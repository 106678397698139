import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import ItemDetail from "./ItemDetail";
import About from "./pages/About";
import Feedback from "./pages/feedback";
import Topics from "./pages/Topics";
import Upload from "./pages/upload";

ReactDOM.render(
  <Router>
    
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/પત્ર/:title" element={<ItemDetail />} />
      <Route path="/About" element={<About />} />
      <Route path="/Feedback" element={<Feedback />} />
      <Route path="/Topics" element={<Topics />} />
      <Route path="/Upload" element={<Upload />} />
      
      
    </Routes>
  </Router>,
  document.getElementById("root")
);
