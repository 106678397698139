import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Viewer from "react-viewer";



// Mock item data
const items = [
  {
    id: 1,
    title: "પ્રાપ્તિ૨૦૨૪",
    description: "First Patra Prapti no patra",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
    ],
  },
  {
    id: 2,
    title: "Item 2",
    description: "This is the second item's description.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
    ],
  },
];

// Array of background images (corrected for React static imports)
const backgroundImages = [
  process.env.PUBLIC_URL + "/images/MSM_Letter.jpg",
  process.env.PUBLIC_URL + "/images/MSMLetter2.jpg",
  process.env.PUBLIC_URL + "/images/MSMLetter3.jpg",
  process.env.PUBLIC_URL + "/images/PSMLetter.jpg",
  process.env.PUBLIC_URL + "/images/PSM_Letter2.jpg",
];

const ItemDetail = () => {
  const { title } = useParams();
  const navigate = useNavigate();

  const item = items.find((item) => item.title === title);

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(""); // State for random background

  // Swipeable handlers for image navigation
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % item.images.length),
    onSwipedRight: () => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + item.images.length) % item.images.length),
  });

  // Automatically open the viewer and set random background image
  useEffect(() => {
    if (item) {
      setIsViewerOpen(true);
    }

    // Randomly select a background image
    const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
    setBackgroundImage(randomImage);
  }, [item]);

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleBack = () => {
    setIsViewerOpen(false);
    navigate("/");
  };

  return (
    <div
      className="item-detail-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div {...swipeHandlers}>
        <Viewer
          visible={isViewerOpen}
          onClose={handleBack}
          images={item.images.map((src) => ({ src }))}
          activeIndex={currentImageIndex}
          zoomable={true}
          rotatable={false}
          downloadable={false}
          attribute={false}
          scalable={false}
          disableMouseZoom
        />
      </div>
    </div>
  );
};

export default ItemDetail;
