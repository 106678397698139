import React from 'react';
import Navbar from "../Navbar/Navbar";

export default function Topics() {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-br text-white">
        {/* Graphic */}
        <div className="relative">
          <div className="absolute top-0 left-0 w-36 h-36 bg-purple-400 rounded-full opacity-30 animate-bounce"></div>
          <div className="absolute top-10 left-10 w-24 h-24 bg-blue-300 rounded-full opacity-40 animate-spin-slow"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-32 h-32 text-white opacity-80"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        </div>

        {/* Text Content */}
        <h1 className="text-5xl font-extrabold mb-4 drop-shadow-md text-orange-300">
  Coming Soon...
</h1>


        <p className="text-lg text-white/90 mb-6 text-center max-w-lg text-gray-400">
          We're creating something amazing . Please check back later!<br/> <br/>Jay Swaminarayan!!
        </p>
      </div>
    </>
  );
}
