import React, { useState } from "react";
import { ThemeProvider } from "./ThemeContext";
import Navbar from "./Navbar/Navbar";
import "./index.css";
import ashirwadsvg from "./images/Untitled design (1).svg"
import nosearch from "./images/searchnotfound.png"

// Mock item data
const items = [
  {
    id: 1,
    title: "પ્રાપ્તિ૨૦૨૪",
    about: "First Patra Prapti no patra",
    description: "First Patra Prapti no patra",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
    ],
  },
  {
    id: 2,
    title: "Item 2",
    about: "First Patra Prapti no patra",
    description: "This is the second item's description.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
    ],
  },
];

const App = () => {
  const [searchQuery, setSearchQuery] = useState("");
  // eslint-disable-next-line
  const [searchedQuery, setSearchedQuery] = useState(""); // For storing the query after "Search"
  const [filteredItems, setFilteredItems] = useState(items); // Initially display all items

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchedQuery(searchQuery); // Save the search query
    setFilteredItems(
      items.filter((item) =>
        item.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  return (
    <ThemeProvider>
      <>
        {/* Navbar */}
        <Navbar />

        {/* Content */}
        <div className="pt-16">
        <div className="flex justify-center my-6 sm:my-14">
  <img
    src={ashirwadsvg}
    alt="Ashirwad Text"
    className="w-60 h-auto sm:w-80 md:w-96 lg:w-[30rem]"
    style={{
      backgroundColor: "white", // Explicitly set a white background
      boxShadow: "none", // Remove shadow
      padding: 0,
      margin: 0,
    }}
  />
</div>

          
          

          <div className="sm:m-4 md:m-16">
            {/* Search Form */}
            <form
              className="flex justify-center mb-8 px-4"
              onSubmit={handleSearch}
            >
              <div className="flex items-center border border-gray-300 rounded-lg shadow-sm bg-white px-4 py-2 w-full max-w-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500 flex-shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input
  type="text"
  placeholder="Search Name, Date, Topic..."
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  className="flex-grow pl-3 text-gray-700 focus:outline-none placeholder-gray-500"
  style={{
    fontSize: "16px", // Ensure font size is 16px or greater
  }}
/>

                <button
                  type="submit"
                  className="bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded-lg hover:bg-blue-700 transition"
                >
                  Search
                </button>
              </div>
            </form>

            {/* Display Items or No Results Message */}
            {filteredItems.length > 0 ? (
              <div className="grid gap-6 justify-center sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {filteredItems.map((item) => (
                  <div
                    key={item.id}
                    className="relative"
                    style={{
                      width: "319px",
                      height: "400px",
                      marginTop: "40px",
                      marginBottom: "64px",
                    }}
                  >
                    {/* Image */}
                    <a href={`/પત્ર/${item.title}`} className="block">
                      <img
                        src={item.images[0]}
                        alt={item.title}
                        className="rounded-lg object-cover w-full h-full"
                      />
                    </a>

                    {/* Title and Description Overlay */}
                    <div
                      className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white text-sm px-4 py-2 flex items-center justify-between"
                      style={{
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      <div>
                        <p className="font-semibold">{item.title}</p>
                        <p className="text-gray-300 text-xs">
                          @{item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[50vh] text-center">
              <img
                src={nosearch}
                alt="Not Found"
                className="w-48 md:w-60 lg:w-72 mb-6"
                style={{
                  backgroundColor: "transparent", // Ensures no background
                  padding: 0, // Removes any padding
                  margin: 0, // Removes any margin
                  boxShadow: "none", // Ensures no shadow
                }}
              />
              <h2 className="text-xl md:text-2xl font-bold mt-6">
                Result Not Found
              </h2>
              <p className="text-gray-500 mt-2 mb-6">
                Whoops ... this letter is not yet available 
              </p>
              <button
                onClick={() => setSearchQuery("")}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg text-sm hover:bg-blue-700 transition"
              >
                Go Back
              </button>
            </div>
            
            )}
          </div>
        </div>
      </>
    </ThemeProvider>
  );
};

export default App;
