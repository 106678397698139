import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import swamishreejisvg from "../images/Untitled design (2).svg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white shadow-md fixed w-full z-10">
      {/* Navbar Container */}
      <div className="flex items-center justify-between h-14 px-2"> {/* Reduced height */}
        {/* Logo */}
        <div className="ml-2"> {/* Added 8px space on the left */}
          <img
            src={swamishreejisvg}
            alt="Swamishreeji Logo"
            className="h-10 w-auto" // Adjusted size of the logo
            style={{
              background: "transparent", // No background
              boxShadow: "none", // No shadow
            }}
          />
        </div>

        {/* Hamburger Icon */}
        <div className="mr-1"> {/* Added 4px space on the right */}
          <FaBars
            className="text-gray-900 h-6 w-6 cursor-pointer"
            onClick={toggleMenu}
          />
        </div>
      </div>

      {/* Sliding Menu */}
      <div
        className={`fixed top-0 right-0 h-full bg-white shadow-lg w-64 transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex justify-between items-center px-4 py-4 border-b">
          <span className="text-lg font-bold">Menu</span>
          <FaTimes
            className="text-gray-900 h-6 w-6 cursor-pointer"
            onClick={toggleMenu}
          />
        </div>
        <div className="px-4 py-2">
          <a
            href="/"
            className="block py-2 text-gray-700 hover:bg-gray-100"
          >
            Home
          </a>
          <a
            href="/About"
            className="block py-2 text-gray-700 hover:bg-gray-100"
          >
            About
          </a>
          <a
            href="/Topics"
            className="block py-2 text-gray-700 hover:bg-gray-100"
          >
            Topics
          </a>
          <a
            href="/Upload"
            className="block py-2 text-gray-700 hover:bg-gray-100"
          >
            Upload
          </a>
          <a
            href="/feedback"
            className="block py-2 text-gray-700 hover:bg-gray-100"
          >
            Feedback
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
