

import React from 'react';
import Navbar from "../Navbar/Navbar";

export default function feedback() {
  return (
    <>
    <Navbar/>
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src="https://form.jotform.com/241548574561059" // Replace with your JotForm embed URL
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="Upload Form"
        allowFullScreen
      ></iframe>
    </div>
    </>
  );
}
